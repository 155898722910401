<template>
  <div style="background: #fff">
    <div class="banner flex_row_round_center"></div>
    <div class="qysqContent">
      <div class="content-top">
        <div class="title">请选择具体的问题类型！</div>
      </div>
      <div class="question">
        <div
          class="questionItem flex-row-between"
          v-for="(item, index) in questionList"
          :key="index"
        >
          <div class="flex-row">
            <div class="leftTitle">诉求问题</div>
            <p class="middleTxt">
              {{ item.label }}
            </p>
          </div>
          <div class="tagDiv">
            <!-- <div class="tag tag1" @click="tonewpage(item.label)">咨询</div>
            <div class="tag tag2" @click="tonewpage(item.label)">
              建议
              <div class="triangle-bottomright"></div>
            </div>
            <div class="tag tag3" @click="tonewpage(item.label)">
              诉求
              <div class="triangle-topleft"></div>
            </div> -->
            <div class="tag4" @click="tonewpage(item.label)">我要提诉求</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const suqiu_data = [];
import { mapGetters } from "vuex";
import { LOGIN_URL, LOGIN_URL_BACK } from "@/store/mutation-types";
export default {
  components: {},
  props: {},
  data() {
    return {
      titleType: 2,
      suqiu_data,
      suqiu_dataCache: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      questionList: [],
    };
  },
  created() {
    // this.getInitData();
    this.getQuestion();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      token: "getToken",
    }),
  },
  methods: {
    checkDetail(obj) {
      let object = JSON.stringify(obj);
      this.$router.push({ name: "qysqDetail", params: { object: object } });
    },
    tonewpage(sqProblemType) {
      if (this.userInfo !== null && this.token !== "") {
        sessionStorage.setItem("sqProblemType", sqProblemType);
        this.$router.push("/launchSuqiu");
      } else if (this.userInfo === null && this.token === "") {
        // this.$message.error("错误：请先登录后再访问");
        this.toLogin();
      } else {
        // this.$message.error("错误：用户信息过期，请重新登录");
        this.toLogin();
      }
    },
    toMySuqiu() {
      //如果没有登录就让先登录
      if (this.userInfo !== null && this.token !== "") {
        this.$router.push({ name: "myAppeal" });
      } else if (this.userInfo === null && this.token === "") {
        // this.$message.error("错误：请先登录后再访问");
        this.toLogin();
      } else {
        // this.$message.error("错误：用户信息过期，请重新登录");
        this.toLogin();
      }
    },
    currentChange(val) {
      this.currentPage = val;
      this.getInitData();
    },
    // 获取诉求公开的数据
    // getInitData() {
    //   let that = this;
    //   let url = "/dev-api/business/handle/sq/publicInfo";
    //   let param = {
    //     currentPage: that.currentPage,
    //     pageSize: that.pageSize,
    //   };
    //   that.$httpApi
    //     .post(url, param)
    //     .then((res) => {
    //       let data = res.data.sqPublicInfos.list;
    //       that.total = res.data.sqPublicInfos.total;
    //       for (var key in data) {
    //         data[key]["type"] = "咨询";
    //       }
    //       that.suqiu_data = data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // 获取诉求问题
    getQuestion() {
      let that = this;
      let url = "/dev-api/system/dict/getDictListByType?dictType=sq_problem";
      that.$httpApi
        .get(url)
        .then((res) => {
          that.questionList = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    toLogin() {
      this.$router.push({path:"/login"})
    },
  },
};
</script>

<style lang='scss' scoped>
* {
  font-family:Microsoft YaHei;
  color: #333;
}
.flex_row_round_center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex_row_between_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner {
  width: 100%;
  height: 200px;
  position: relative;
  background: url("../../assets/images/底图.png") center / 100% 100% no-repeat;
}
.qysqContent {
  width: 1440px;
  min-height: 300px;
  // background-color: #ccc;
  border: 1px solid #e2e2e2;
  margin: 10px auto;
  padding-bottom: 10px;
  .content-top {
    width: 100%;
    height: 60px;
    border-bottom: 4px solid #dd0303;
    position: relative;
    .title {
      width: 240px;
      height: 40px;
      background-color: #d40000;
      border-radius: 5px 5px 0 0;
      font-size: 20px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      position: absolute;
      bottom: -1px;
      left: 30px;
    }
  }
  .questionItem {
    font-size: 20px;
    .leftTitle {
      width: 80px;
      height: 80px;
      padding: 10px 12px;
      background-color: #ff9b2b;
      color: #fff;
      font-weight: 600;
      text-align: center;
      margin: 10px 10px 10px 30px;
    }
    .middleTxt {
      color: #000;
      font-weight: 900;
    }
    .tagDiv {
      display: flex;
      width: 260px;
      margin-right: 30px;
    }
    .tag {
      cursor: pointer;
      width: 80px;
      height: 30px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #ff6f00;
    }
    .tag1 {
      width: 90px;
      border-radius: 20px 0 0 20px;
    }
    .tag2 {
      background-color: #d40000;
      position: relative;
      .triangle-bottomright {
        position: absolute;
        top: 0;
        left: -20px;
        width: 0;
        height: 0;
        border-bottom: 30px solid #d40000;
        border-left: 20px solid transparent;
      }
    }

    .tag3 {
      width: 90px;
      background-color: #ff6f00;
      border-radius: 0 20px 20px 0;
      position: relative;
      .triangle-topleft {
        position: absolute;
        top: 0;
        right: 70px;
        width: 0;
        height: 0;
        border-top: 30px solid #d40000;
        border-right: 20px solid transparent;
      }
    }
    .tag4 {
      cursor: pointer;
      width: 160px;
      height: 30px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #ff6f00;
      border-radius: 20px;
    }
  }
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $col-theme;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: $col-theme;
  }
  .filterItem-right span {
    color: $col-theme;
  }
  .filterItem-right .el-icon-caret-bottom {
    color: $col-theme !important;
  }
  .el-checkbox-button__inner:hover {
    color: $col-theme;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
}
</style>
